<template>
  <div id="notifications">
    <p class="title">{{$t('settings.notifications.title')}}</p>
    <md-checkbox v-model="optStayNotifications">
      {{$t('settings.notifications.optStayNotifications')}}
      <br>
      <span class="note">{{$t('settings.notifications.optStayNotifications2')}}</span>
    </md-checkbox>
    <md-checkbox v-model="optPromoNotifications">
      {{$t('settings.notifications.optPromoNotifications')}}
      <br>
      <span class="note">{{$t('settings.notifications.optPromoNotifications2')}}</span>
    </md-checkbox>
    <div class="save" @click="apply">{{$t('settings.save')}}</div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      optStayNotifications: false,
      optPromoNotifications: false
    }
  },
  methods: {
    apply() {
      this.$api.patch(`user`, {
        optStayNotifications: this.optStayNotifications,
        optPromoNotifications: this.optPromoNotifications
      })
      .then(() => {
        window.location.reload()
      })
    }
  },
  mounted() {
    this.$api.get(`user`)
    .then(res => {
      this.optStayNotifications  = res.data.data.optStayNotifications
      this.optPromoNotifications = res.data.data.optPromoNotifications
    })
  },
}
</script>

<style lang="scss">
  #notifications {
    max-width: 420px;
    margin: 0 auto;
    font-family: Roboto;
    word-wrap: break-word;
    padding: 0 15px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      color: #47525E;
      font-size: 30px;
      margin-bottom: 20px;
      text-align: center;
      line-height: 1;
    }

    .note {
      color: #47525E;
      font-family: Roboto;
      font-size: 14px;
    }

    .md-checkbox {
      color: #47525E;
      font-family: Roboto;
      font-size: 20px;
      margin-bottom: 50px;
      &.md-checked {
        .md-checkbox-container {
          background-color: #8190a5;
          border-color: #8190a5;

          .md-ripple {
            color: #8190a5;
          }
        }
      }
    }
    .save {
      background-color: #47525E;
      border-radius: 5px;
      height: 35px;
      width: 121px;
      line-height: 35px;
      color: #FFFFFF;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 10px;
      cursor: pointer;
      transition: .2s all;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
</style>